import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkCredentials } from '../common/auth.helper';

interface PrivateRouteProps {
  component: React.FC;
  path: string;
  layout: FC;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  if (!checkCredentials()) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default PrivateRoute;
