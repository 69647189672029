import React, { useCallback, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import './styles.scss';
import { Link, useHistory } from 'react-router-dom';
import { Can } from 'src/common/ability';
import packageJson from '../../../package.json';

const HeaderNav: React.FC = () => {
  const history = useHistory();
  const [username, setUserName] = useState('');

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('@user:name');
    if (user) {
      setUserName(user);
    }
  }, []);

  return (
    <>
      {/* <Navbar bg="dark" fixed="top" variant="dark" expand="lg"> */}
      <Navbar collapseOnSelect fixed="top" expand="lg">
        <Navbar.Brand href="/schedules">
          Agendareis{' '}
          <span className="version">{`v${packageJson.version}`}</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Can I="SHOW" a="PROFESSIONALS">
              <Nav.Link as={Link} eventKey="0" to="/professional/list">
                Profissionais
              </Nav.Link>
            </Can>
            <Can I="SHOW" a="PATIENTS">
              <Nav.Link as={Link} eventKey="0" to="/patient/list">
                Pacientes
              </Nav.Link>
            </Can>
            <Can I="SHOW" a="AGENDA">
              <Nav.Link as={Link} eventKey="0" to="/schedules">
                Agenda
              </Nav.Link>
            </Can>
            <Can I="SHOW" a="USERS">
              <Nav.Link as={Link} eventKey="0" to="/user/list">
                Usuários
              </Nav.Link>
            </Can>
          </Nav>
          <Nav>
            <Nav.Link disabled>
              Olá <b>{username}</b>
            </Nav.Link>
            <Nav.Link onClick={logout} href="#">
              Sair
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default HeaderNav;
