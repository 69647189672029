import React, { createContext } from 'react';

import { AnyAbility, Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';

const ability = new Ability();
const AbilityContext = createContext({} as AnyAbility);
const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider: React.FC = ({ children }) => {

  let permissions = localStorage.getItem('@user:permissions');

  if (permissions) {
    let perme = JSON.parse(permissions);
    ability.update(perme);
  }


  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export { AbilityProvider, AbilityContext, Can, ability };
