import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderNav from 'src/components/HeaderNav';

import './styles.scss';

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <Container fluid className="customized">
      <HeaderNav />
      {children}
    </Container>
  );
};

export default DefaultLayout;
