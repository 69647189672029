import React, { lazy, FC } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthLayout from '../Layouts/Auth';
import PublicLayout from '../Layouts/Default';
import PublicRoute from './PublicRoute';

// const Calendar = lazy(() => import('../pages/Calendar'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const ProfessionalList = lazy(() => import('../pages/Professional/list'));
const ProfessionalDetail = lazy(() => import('../pages/Professional/detail'));
const Schedules = lazy(() => import('../pages/Schedules'));
const PatientDetail = lazy(() => import('../pages/Patient/detail'));
const UsersList = lazy(() => import('../pages/Users/list'));
const UsersDetail = lazy(() => import('../pages/Users/detail'));
// const SecretariesList = lazy(() => import('../pages/Secretaries/list'));
// const SecretariesDetail = lazy(() => import('../pages/Secretaries/detail'));
const PatientList = lazy(() => import('../pages/Patient/list'));

const Login = lazy(() => import('../pages/Auth'));

const Routes: FC = () => {
  return (
    <Switch>
      <PublicRoute layout={AuthLayout} path="/login" component={Login} />
      <PrivateRoute
        layout={PublicLayout}
        path="/professional/list"
        component={ProfessionalList}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/professional/detail/:id"
        component={ProfessionalDetail}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/professional/detail"
        component={ProfessionalDetail}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/patient/detail/:id"
        component={PatientDetail}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/patient/detail"
        component={PatientDetail}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/patient/list"
        component={PatientList}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/schedules"
        component={Schedules}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/user/detail/:id"
        component={UsersDetail}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/user/detail"
        component={UsersDetail}
      />
      <PrivateRoute
        layout={PublicLayout}
        path="/user/list"
        component={UsersList}
      />
      {/* <PrivateRoute layout={PublicLayout} path="/secretaries/list" component={SecretariesList} />
      <PrivateRoute layout={PublicLayout} path="/secretaries/detail/:id" component={SecretariesDetail} />
      <PrivateRoute layout={PublicLayout} path="/secretaries/detail" component={SecretariesDetail} /> */}
      <PrivateRoute path="*" layout={PublicLayout} component={Schedules} />
    </Switch>
  );
};

export default Routes;
