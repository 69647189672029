import React from 'react';
import ReactDOM from 'react-dom';

import './styles/global.scss';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
