import { ability } from './ability';

export const setUserCredentials = data => {
  localStorage.setItem('@user:token', data.token);
  // const permissions = [
  //   {
  //     action: ['read'],
  //     subject: ['all'],
  //   },
  // ];

  ability.update(data.userRules);
  localStorage.setItem('@user:permissions', JSON.stringify(data.userRules));
  localStorage.setItem('@user:name', data.userInfo.username);
  localStorage.setItem('@user:id', data.userInfo.id);
};

const getToken = () => {
  const token = localStorage.getItem('@user:token');
  return token;
};

export const checkCredentials = () => {
  if (getToken()) {
    return true;
  }
  return false;
};

export const getProfessionalId = (): number => {
  const professionalId = sessionStorage.getItem('@agendareis/professionalId');
  return Number(professionalId);
};

export const setProfessionalId = (professionalId): void => {
  sessionStorage.setItem('@agendareis/professionalId', String(professionalId));
};
