import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AbilityProvider } from './common/ability';
import Routes from './routes';

const App: React.FC = () => (
  <AbilityProvider>
    <BrowserRouter>
      <Suspense fallback={<span>Loading...</span>}>
        <ToastContainer />
        <Routes />
      </Suspense>
    </BrowserRouter>
  </AbilityProvider>
);

export default App;
